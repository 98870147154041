import auth from '@/middleware/auth';
import hasReports from '@/middleware/hasReports';

const ReportsView = () => import('@/views/ReportsView.vue');

const routes = [
	{
		path: '/',
		component: ReportsView,
		name: 'reports',
		meta: { middleware: [auth, hasReports] }
	}
];

export default routes;
