<template>
	<div class="mf-app-container scroll-none" id="mf-reports-app">
		<router-view />
	</div>
</template>

<script>
// Update app version
import { brandReducer } from '@superpagos/mf-store';
import version from './version';

export default {
	name: 'App',
	mounted() {
		brandReducer.setGlobalVersion({ app: 'reports', version });
	},
	components: {}
};
</script>

<style scoped>
#mf-reports-app {
	overflow-x: hidden;
}
</style>
