import { navigateToUrl } from 'single-spa';
export default ({ next, Store }) => {
	var user = Store.getters.user;

	if (
		!user?.Rol?.permissions?.some(permission =>
			permission.includes('reports.draw')
		)
	) {
		navigateToUrl('/');
		return;
	}

	return next();
};
