// import * as singleSpa from 'single-spa';

export default function auth({ Store, next }) {
	if (!Store.getters.user) {
		window.location = '/login';
		// singleSpa.navigateToUrl('/login');
		window.clearInterval(window.threadRealTime);
		return;
	}

	return next();
}
