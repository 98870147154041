import './set-public-path';
import Vue from 'vue';
import singleSpaVue from 'single-spa-vue';

import App from './App.vue';
import BootstrapVue from 'bootstrap-vue';
import store from '@superpagos/mf-store';
import router from './router';
import services, { MixinService } from '@superpagos/mf-services';
import {
	AppLoader,
	AppInput,
	AppButton,
	AppModal
} from '@superpagos/mf-custom-components';
import vuex from 'vuex';

services.ApiService.init(store);
services.ApiServiceRefacilOld.init(store);
services.ApiServiceSell.init(store);
services.ApiServiceReports.init(store);

Vue.use(BootstrapVue);
Vue.use(vuex);
Vue.mixin({ methods: MixinService });

Vue.component('app-loader', AppLoader);
Vue.component('app-input', AppInput);
Vue.component('app-button', AppButton);
Vue.component('app-modal', AppModal);

Vue.config.productionTip = false;
var Store = store;

Vue.directive('can', {
	bind(el, binding) {
		var user = Store.state.auth.user;
		if (user.Rol.permissions.indexOf(binding.value) == -1) {
			el.style.display = 'none';
		}
	}
});

const vueLifecycles = singleSpaVue({
	Vue,
	appOptions: {
		store,
		render(h) {
			return h(App, {
				props: {
					// single-spa props are available on the "this" object. Forward them to your component as needed.
					// https://single-spa.js.org/docs/building-applications#lifecycle-props
					// if you uncomment these, remember to add matching prop definitions for them in your App.vue file.
					/*
          name: this.name,
          mountParcel: this.mountParcel,
          singleSpa: this.singleSpa,
          */
				}
			});
		},
		router
	}
});

export { default as version } from './version';
export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;

services.ApiServiceReports.setVue(Vue);
